<template>
  <div class="profileLabel" style="padding-left: 1.75rem;">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      <span>{{ $route.name }}</span>
      <div class="subHeadTitle">{{ $route.name }}</div>
    </div>
    <div class="Profile_Parent panel">
      <div class="inner_panel">
        <div class="Profile_header">
          <div class="Profile_avatar">
            <img style="" :src="USER_AVATAR" alt="" />
          </div>
          <div class="Profile_user">
            <div>
              <template v-if="!loadingProfile">
                <p>{{ changeRoleView(credentials.currentUserRole) }}</p>
                <p>{{ credentials.name + ` / ID ${credentials.id}` }}</p>
              </template>
              <template v-else>
                <i class="fas fa-sync fa-spin"></i>
              </template>
            </div>
            <div>
              <p>
                <i class="fas fa-user-tie"></i>
                {{ credentials.registrationEmail }}
              </p>
              <p>
                <i class="fas fa-envelope"></i>
                {{ credentials.correspondenceEmail }}
              </p>
              <p><i class="fas fa-phone"></i> {{ credentials.phone }}</p>
            </div>
          </div>
        </div>
        <div
          style="border-bottom: 1px dashed #dcdcdc;margin: 0 -1.875rem;"
        ></div>
        <div class="Profile_body">
          <div
            :class="{ labelActive: currentLabel === 1 }"
            class="Profile_label"
          >
            <div @click="changeLabel(1)" class="Profile_label_header">
              <div class="Profile_label_header_ico">
                <i class="fas fa-angle-down"></i>
              </div>
              <span>Date personale</span>
            </div>
            <div
              @click.stop=""
              class="Profile_label_body"
              :class="loadingStatus.personalData ? 'Profile_loading' : ''"
            >
              <div class="lbl1">
                <div class="form-group">
                  <b>CNP</b>
                  <inp-number
                    v-model="userData.cnp"
                    :maxChars="13"
                    :isPrice="false"
                    :disabled="true"
                  />
                </div>
                <div class="form-group">
                  <b>Data creării</b>
                  <input
                    type="text"
                    :value="userData.registrationDate"
                    class="vInput form-disabled"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <b>E-mail de corespondență</b>
                  <input
                    type="text"
                    v-model="userData.correspondenceEmail"
                    class="vInput"
                  />
                </div>
                <div class="form-group">
                  <b>Telefon</b>
                  <vue-tel-input
                    @input="(a, b) => (phoneStatus = b || null)"
                    v-model="userData.phone"
                  />
                </div>
              </div>
              <div class="right">
                <div
                  @click="modifyPersonalData"
                  :class="
                    !personalDataIsReady || btdDisabled.btn4
                      ? 'Btn_disabled'
                      : ''
                  "
                  class="Button1"
                >
                  <div class="icon">
                    <img
                      src="../assets/media/for_table/circle_ADD_new_etap.svg"
                      alt=""
                    />
                  </div>
                  <span class="Btn_content">
                    Salvează
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="{ labelActive: currentLabel === 2 }"
            class="Profile_label"
          >
            <div @click="changeLabel(2)" class="Profile_label_header">
              <div class="Profile_label_header_ico">
                <i class="fas fa-angle-down"></i>
              </div>
              <span>Date cont</span>
            </div>
            <div
              @click.stop=""
              class="Profile_label_body"
              :class="loadingStatus.accountData ? 'Profile_loading' : ''"
            >
              <!-- <div class="form-group Fus_orar_title">
                  <b style="transform: translateY(.5em);">Fus orar</b>
                </div> -->
              <div class="lbl2">
                <div class="form-group registrationEmail">
                  <b>Username</b>
                  <input
                    type="text"
                    v-model="userData.registrationEmail"
                    class="vInput form-disabled"
                    disabled
                  />
                </div>
              </div>
              <!--      <div class="right">
                <div class="Button1 Btn_disabled">
                  <div class="icon">
                    <img src="../assets/media/for_table/circle_ADD_new_etap.svg" alt="">
                  </div>
                  <span class="Btn_content">
                    Salvează
                  </span>
                </div>
              </div> -->
            </div>
          </div>
          <div
            :class="{ labelActive: currentLabel === 3 }"
            class="Profile_label"
          >
            <div @click="changeLabel(3)" class="Profile_label_header">
              <div class="Profile_label_header_ico">
                <i class="fas fa-angle-down"></i>
              </div>
              <span>Poza de profil</span>
            </div>
            <div @click.stop="" class="Profile_label_body">
              <div class="lbl3">
                <cropper
                  :key="currentLabel"
                  :blobMode="true"
                  :showAvatar="true"
                  :prepareBlobName="
                    (file) =>
                      this.isString(file.name)
                        ? `userAvatar.${file.name.split('.').at(-1)}`
                        : 'File'
                  "
                  @save="saveAvatar"
                />
              </div>
            </div>
          </div>
          <div
            :class="{ labelActive: currentLabel === 4 }"
            class="Profile_label"
          >
            <div @click="changeLabel(4)" class="Profile_label_header">
              <div class="Profile_label_header_ico">
                <i class="fas fa-angle-down"></i>
              </div>
              <span>Modifică parola</span>
            </div>
            <div
              @click.stop=""
              class="Profile_label_body"
              :class="loadingStatus.modifyPassword ? 'Profile_loading' : ''"
            >
              <div
                role="alert"
                class="alert alert-info"
                style="margin-bottom: 1rem;"
              >
                <span
                  >Parola trebuie să conțină minimum 6 caractere și să fie
                  compusă din cel puțin o cifră, o minusculă și o majusculă.
                </span>
                <span
                  >Caractere permise: [a-z], [A-Z], [0-9] și un caracter special
                  ($, @, !, %, ^, *, etc.).</span
                >
              </div>
              <div class="lbl4">
                <div>
                  <div class="form-group">
                    <b>Parola veche</b>
                    <div class="input-icons">
                      <input
                        id="oldPassword"
                        :type="showPasswordOld ? 'text' : 'password'"
                        v-model="userData.passwordChange"
                        class="vInput customPassword"
                        autocomplete="new-password"
                        autocapitalize="false"
                        autocorrect="false"
                      />
                      <i
                        @click="showPasswordOld = !showPasswordOld"
                        class="fas password-icon"
                        :class="{
                          'fa-eye-slash': showPasswordOld,
                          'fa-eye': !showPasswordOld,
                        }"
                      >
                      </i>
                    </div>
                  </div>
                  <div class="form-group">
                    <b>Parola nouă</b>
                    <div class="input-icons">
                      <input
                        id="newPassword"
                        :type="showPasswordNew ? 'text' : 'password'"
                        v-model="userData.passwordNew"
                        class="vInput customPassword"
                        autocomplete="new-password"
                        autocapitalize="false"
                        autocorrect="false"
                      />
                      <i
                        @click="showPasswordNew = !showPasswordNew"
                        class="fas password-icon"
                        :class="{
                          'fa-eye-slash': showPasswordNew,
                          'fa-eye': !showPasswordNew,
                        }"
                      >
                      </i>
                    </div>
                    <b
                     class="password-error"
                      v-if="userData.passwordNew && !validatePassword(userData.passwordNew.trim())"
                      >Parola nu este sigură.</b
                    >
                  </div>
                  <div class="form-group">
                    <b>Confirmă parola</b>
                    <div class="input-icons">
                      <input
                        id="newPasswordConfirm"
                        :type="showPasswordNewConfirm ? 'text' : 'password'"
                        v-model="userData.passwordConfirm"
                        class="vInput customPassword"
                        autocomplete="new-password"
                        autocapitalize="false"
                        autocorrect="false"
                      />
                      <i
                        @click="
                          showPasswordNewConfirm = !showPasswordNewConfirm
                        "
                        class="fas password-icon"
                        :class="{
                          'fa-eye-slash': showPasswordNewConfirm,
                          'fa-eye': !showPasswordNewConfirm,
                        }"
                      >
                      </i>
                    </div>
                    <b
                     class="password-error"
                      v-if="userData.passwordConfirm && String(userData.passwordNew).trim() != String(userData.passwordConfirm).trim()"
                      >Parolele nu coincid.</b
                    >
                  </div>
                </div>
                <div class="right">
                  <div
                    @click="modifyPasswordData"
                    :class="!changePasswordIsReady ? 'Btn_disabled' : ''"
                    class="Button1"
                  >
                    <div class="icon">
                      <img
                        src="../assets/media/for_table/circle_ADD_new_etap.svg"
                        alt=""
                      />
                    </div>
                    <span class="Btn_content">
                      Salvează
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="{ labelActive: currentLabel === 5 }"
            class="Profile_label"
          >
            <div @click="changeLabel(5)" class="Profile_label_header">
              <div class="Profile_label_header_ico">
                <i class="fas fa-angle-down"></i>
              </div>
              <span>Roluri utilizator</span>
            </div>
            <div
              @click.stop=""
              class="Profile_label_body"
              style="overflow-x: auto;padding: 0;max-height: 55rem;"
            >
              <table class="table table-striped roles-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Rol</th>
                    <th scope="col">Instituție</th>
                    <th scope="col">Structură organizatorică</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!userRoles.length">
                    <td colspan="4" style="text-align: center;">
                      <h4 class="prettyH4" style="color: #888">
                        <i class="fas fa-times"></i>
                        Nu sunt roluri
                      </h4>
                    </td>
                  </tr>
                  <tr
                    v-else
                    v-for="(role, index) in userRoles"
                    :key="role.id"
                    :class="{ 'active-row': role.isActive }"
                  >
                    <th scope="row">{{ index + 1 }}</th>
                    <td :style="role.style">{{ role.roleName }}</td>
                    <td>{{ role.instName }}</td>
                    <td>{{ role.depName }}</td>
                    <td style="font-weight: bold;" v-html="role.status"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="../assets/profile.css"></style>

<script>
import { USER_ME_MODIFY, USER_AVATAR_UPLOAD, USER_ME } from "../api.js";
import { mapGetters, mapMutations } from "vuex";
import Cropper from "@/components/FileInput";

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  components: {
    Cropper,
  },
  data() {
    return {
      showPasswordOld: false,
      showPasswordNew: false,
      showPasswordNewConfirm: false,
      phoneStatus: null,
      loadingStatus: {
        personalData: false,
        accountData: false,
        profileImage: false,
        modifyPassword: false,
      },
      btdDisabled: {
        btn4: false,
      },
      credentials: {
        id: "",
        uuid: "",
        cnp: "",
        currentUserRole: "",
        name: "",
        registrationEmail: "",
        correspondenceEmail: "",
        phone: "",
      },
      userData: {
        cnp: "",
        registrationDate: "",
        registrationEmail: "",
        correspondenceEmail: "",
        phone: "",
        passwordChange: "",
        passwordConfirm: "",
        passwordNew: "",
      },
      userRoles: [],
      currentLabel: 0,
    };
  },
  computed: {
    ...mapGetters(["USER_AVATAR"]),
    personalDataIsReady() {
      this.btdDisabled.btn4 = false;
      const x = this.userData;
      const y = this.credentials;

      if (
        String(x.cnp).trim() !== String(y.cnp).trim() ||
        String(x.correspondenceEmail).trim() !==
          String(y.correspondenceEmail).trim() ||
        (String(x.phone).trim() !== String(y.phone).trim() &&
          this.phoneStatus?.valid)
      ) {
        if (String(x.cnp).trim().length === 13) {
          return true;
        }
      }
      return false;
    },
    changePasswordIsReady() {
      if (
        this.userData.passwordChange.trim().length > 4 &&
        this.validatePassword(this.userData.passwordConfirm.trim()) &&
        this.validatePassword(this.userData.passwordNew.trim()) &&
        String(this.userData.passwordNew).trim() ===
          String(this.userData.passwordConfirm).trim()
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations({ addWindow: "addWindow", setUserAvatar: "setUserAvatar" }),
    validatePassword(password) {
      const re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){6,16}$/gm;
      return re.test(String(password));
    },
    changeLabel(label) {
      if (this.currentLabel !== label) {
        this.currentLabel = label;
      } else {
        this.currentLabel = 0;
      }
      this.getUserData();
    },

    showPassword(id) {},
    saveAvatar(file, base64) {
      if (!file || typeof file != "object") return;
      this.currentLabel = -1;

      const formData = new FormData();
      formData.append("file", file);

      const error = (msg) => {
        this.$toastr.e(msg || "USER_AVATAR_BASE64");
        this.setLoad();
      };

      this.setSafeLoad(12000);
      USER_AVATAR_UPLOAD(formData)
        .then((response) => {
          if (response?.meta?.HttpStatusCode == 200) {
            this.$toastr.s("Poza de profil a fost salvată");
            this.setUserAvatar(base64);
            localStorage.setItem("USER_AVATAR_BASE64", base64);
            this.setLoad();
          } else {
            error();
          }
          this.avatarEditor = false;
        })
        .catch(error);
    },
    getUserCredentials() {
      const err = (msg) => {
        this.goToHomePage();
        this.$toastr.e(msg || "Încercați mai târziu");
      };

      this.loadingProfile = true;

      return USER_ME()
        .then((res) => {
          if (!this.isObject(res?.data?.result)) {
            err();
            return;
          }

          res = res.data.result;

          this.loadingProfile = false;

          const data = this.credentials;
          const currentRole = window.$getRoleAll();

          data.id = res.id || false;
          data.uuid = res.uuid || false;
          data.cnp = res.cnp || "-";
          this.userData.registrationDate = this.toDate(res.createdAt);
          data.currentUserRole = currentRole.role || "-";
          data.name = res.last_name + " " + res.first_name;
          data.registrationEmail = res.email || "-";
          data.correspondenceEmail = res.emailForCommunication || "-";
          data.phone = res.phone || "-";
        })
        .catch(err);
    },
    getUserData() {
      const data = this.userData;
      const source = this.credentials;

      data.cnp = source.cnp || "";
      data.registrationEmail = source.registrationEmail || "";
      data.correspondenceEmail = source.correspondenceEmail || "";
      data.phone = source.phone || "";
    },
    modifyPersonalData() {
      const data = this.userData;
      const errors = [];

      // this.loadingStatus.personalData = true

      if (!parseInt(data.cnp) || String(data.cnp).length !== 13) {
        errors.push("Codul CNP a fost introdus greșit");
      }
      if (!this.validateEmail(data.correspondenceEmail)) {
        errors.push("E-mail-ul de corespondență a fost introdus greșit");
      }
      if (!this.phoneStatus?.valid) {
        errors.push("Numărul de telefon a fost introdus greșit");
      }

      if (errors.length) {
        this.$toastr.e(errors[0], "Sallete esuata");
      } else if (!this.credentials.id && this.credentials.id !== 0) {
        this.$toastr.e(
          "Încercați să vă autentificați din nou",
          "Sallete eșuată"
        );
      } else {
        const sendData = {};
        if (String(data.cnp).trim() !== String(this.credentials.cnp).trim()) {
          sendData.cnp = String(data.cnp);
        }
        if (
          String(data.correspondenceEmail).trim() !==
          String(this.credentials.correspondenceEmail).trim()
        ) {
          sendData.emailForCommunication = String(data.correspondenceEmail);
        }
        if (
          String(data.phone).trim() !== String(this.credentials.phone).trim()
        ) {
          sendData.phone = String(data.phone);
        }

        if (Object.keys(sendData).length) {
          this.loadingStatus.personalData = true;

          USER_ME_MODIFY(sendData)
            .then((response) => {
              if (this.checkHttpStatusCode(response?.meta?.HttpStatusCode)) {
                const credentials = this.credentials;

                this.$toastr.s("Datele personale au fost salvate");

                credentials.cnp = String(data.cnp);
                credentials.correspondenceEmail = String(
                  data.correspondenceEmail
                );
                credentials.phone = String(data.phone);

                this.btdDisabled.btn4 = true;
              } else {
                this.$toastr.e("Încercați mai târziu");
              }
              this.loadingStatus.personalData = false;
            })
            .catch((err) => {
              this.loadingStatus.personalData = false;
              this.$toastr.e(err);
            });
        }
      }
    },
    async modifyPasswordData() {
      const data = this.userData;
      const errors = [];

      if (
        !this.userData.passwordChange.trim().length > 4 ||
        !this.validatePassword(this.userData.passwordConfirm.trim()) ||
        !this.validatePassword(this.userData.passwordNew.trim()) ||
        String(this.userData.passwordNew).trim() !==
          String(this.userData.passwordConfirm).trim()
      ) {
        errors.push("Parola a fost introdusă incorect");
      }

      if (errors.length) {
        this.$toastr.e(errors[0], "Sallete esuata");
        this.emptyPassword();
      } else {
        this.loadingStatus.modifyPassword = true;
        const sendData = {
          password: String(data.passwordNew),
          oldPassword: String(data.passwordChange),
        };

        const response = await USER_ME_MODIFY(sendData);

        if (response && response.meta && response.meta.HttpStatusCode == 200) {
          this.$toastr.s("Parola a fost salvată");
          this.currentLabel = 0;
          this.emptyPassword();
        } else if (response.message) {
          this.$toastr.e("Parola veche a fost introdusă incorect");
        } else {
          this.$toastr.e("Încercați mai târziu");
        }
        this.loadingStatus.modifyPassword = false;
      }
    },
    emptyPassword() {
      const data = this.userData;

      data.passwordChange = "";
      data.passwordNew = "";
      data.passwordConfirm = "";
    },
    initUserRole() {
      const token = this.getToken();
      const stylesMap = {
        superadmin: "color:green;font-weight:700;",
      };
      const statusMap = {
        active: '<span style="color:green;">Activ</span>',
        unconfirmed: '<span style="color:orange;">Necomfirmat</span>',
        disabled: '<span style="color:red;">Dezactivat</span>',
      };

      const currentRole = this.getUserRole(true);

      if (Array.isArray(token?.userRoles)) {
        this.userRoles = token.userRoles.map((e) => ({
          ...e,
          id: e.id ?? this.makeid(6),
          roleName: e.role ? this.changeRoleView(e.role) : "-",
          isActive: e.id === currentRole,
          instName: e.institutionName ?? "",
          depName: e.departmentName ?? "",
          style: stylesMap[e.role] || "",
          status: statusMap[e.userRoleStatus] || e.userRoleStatus || "-",
        }));
      } else this.$toastr.e("Sincronizarea rolurilor utilizatorului a eșuat.");
    },
  },
  async created() {
    this.initUserRole();
    await this.getUserCredentials();
    this.getUserData();
  },
  mounted() {
    document
      .querySelectorAll(".Profile_avatar > img")[0]
      .addEventListener("load", (e) => {
        e.target.style.opacity = 1;
      });
  },
};
</script>

<style>
.Profile_avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Profile_avatar > div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.Profile_avatar img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  transition: transform 200ms, opacity 500ms;
}
.input-icons {
  position: relative;
}

.password-icon {
  position: absolute;
  z-index: 100;
  right: 5px;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.password-error {
  font-size: 11px !important;
  color: red !important;
  margin-left: 5px;
}
</style>
